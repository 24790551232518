<template>
  <div class="dealRequest">
    <!-- 头部导航栏 -->
    <div class="card-header">
      <i class="el-icon-edit"></i>
      <span> Review Favorites</span>
    </div>
    <!-- 底部商品栏 -->
    <div class="requestList">
      <!-- 商品栏 -->
      <div class="requestBox"
           v-for="(item, index) in reqList"
           :key="index">
        <!-- 商品介绍 -->
        <div class="goodItd">
          <!-- 商品图片 -->
          <div class="reqImg">
            <!-- <img src="../../assets/imgs/watch.jpg" alt=""> -->
            <img :src="item.itemImg"
                 alt="" />
          </div>
          <!-- 商品名称和时间 -->
          <div class="reqGoods">
            <div class="reqName">
              <span :class="item.site_class"></span>
              <span @click="freeGood(item)">{{item.product_title}}</span>
            </div>
            <div class="reqSell"><b>Seller User</b> : {{item.firstname}}  {{item.lastname}}</div>
            <div class="reqTime">{{item.create_date | formatDate}}</div>
          </div>
        </div>
        <!-- 商品卖方状态等 -->
<!--        <div class="reqCuntent">-->
<!--          <div class="reqSell"><b>Seller User</b> : {{item.firstname}}  {{item.lastname}}</div>-->
<!--        </div>-->
        <!-- 删除或购买商品 -->
        <div class="reqBtn">
          <el-button type="primary"
                     @click="errorTips(item)"
                     class="error"
                     icon="el-icon-error">Cancel</el-button>
        </div>
        <!-- 消息提醒 -->
        <!-- <span class="information">1</span> -->
      </div>
      <!-- 分页 -->
      <div class="block"
           v-if="reqListBool">
        <div class="blockDiv">
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-sizes="[10, 20, 50, 100]"
                         :page-size="currentPagesize"
                         layout="total,sizes, prev, pager, next, jumper"
                         :total="currentTotal">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 获取接口
import { formatDate } from '../../utils/data.js'
import {
  freeLike, // 获取赠品商品收藏信息
  unlikeGood // cancle商品收藏
} from '../../api/buyerFront'
export default {
  data () {
    return {
      // 分页
      currentPage: 1,
      currentPagesize: 10,
      currentTotal: 0,
      // 商品栏
      reqList: [],
      reqListBool: false
    }
  },
  filters: {
    // 时间格式化
    formatDate (time) {
      const date = new Date(time)
      // // (new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    }
  },
  created () {
    this.getFreelike()
  },
  methods: {
    // 收藏列表
    getFreelike () {
      // 获取赠品商品收藏信息
      freeLike({
        page: this.currentPage,
        size: this.currentPagesize
      }).then(result => {
        if (result.data.code === 200) {
          this.reqList = result.data.data.rows
          this.currentTotal = result.data.data.total
          this.reqList.map(v => {
            // (v.product_image_url.split('"'))
            v.itemImg = v.product_image_url.split('"')[1]
          })
          // 是否显示分页
          if (this.reqList.length > 0) {
            this.reqListBool = true
          } else {
            this.reqListBool = false
          }
        }
        // ('result =>', result)
      }).catch(err => {
        return err
      })
    },
    // cancle提示
    errorTips (item) {
      const h = this.$createElement
      this.$msgbox({
        title: '消息',
        message: h('p', null, [
          h('span', null, 'Are you sure to cancel this request? ')
          // h('i', { style: 'color: teal' }, 'VNode'),
        ]),
        showClose: false,
        showCancelButton: true,
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancle',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            done()
            instance.confirmButtonLoading = false
          } else {
            done()
          }
        }
      })
        .then((result) => {
          // ('result ==>', result)
          this.$msgbox({
            title: '消息',
            message: h('p', null, [h('span', null, 'Cancle success ')]),
            showClose: false,
            showCancelButton: false,
            confirmButtonText: 'confirm',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                const productId = item.product_id
                // cancle商品收藏
                unlikeGood(productId).then(result => {
                  if (result.data.code === 200) {
                    this.$message.success(result.data.message)
                    // this.getFreelike()
                    setTimeout(() => {
                      this.$router.go(0)
                    }, 500)
                  }
                  // ('result =>', result)
                }).catch(err => {
                  return err
                })
                done()
                instance.confirmButtonLoading = false
              } else {
                done()
              }
            }
          })
        })
        .catch((err) => {
          return err
        })
    },
    // 分页
    handleSizeChange (val) {
      // (`每页 ${val} 条`)
      this.currentPagesize = val
      // 获取赠品商品收藏信息
      freeLike({
        page: this.currentPage,
        size: val
      }).then(result => {
        if (result.data.code === 200) {
          this.reqList = result.data.data.rows
          this.currentTotal = result.data.data.total
          this.reqList.map(v => {
            // (v.product_image_url.split('"'))
            v.itemImg = v.product_image_url.split('"')[1]
          })
        }
        // ('result =>', result)
      }).catch(err => {
        return err
      })
    },
    handleCurrentChange (val) {
      // (`当前页: ${val}`)
      // 获取赠品商品收藏信息
      freeLike({
        page: val,
        size: this.currentPagesize
      }).then(result => {
        if (result.data.code === 200) {
          this.reqList = result.data.data.rows
          this.currentTotal = result.data.data.total
          this.reqList.map(v => {
            // (v.product_image_url.split('"'))
            v.itemImg = v.product_image_url.split('"')[1]
          })
        }
        // ('result =>', result)
      }).catch(err => {
        return err
      })
    },
    // 跳转赠品详情
    freeGood (item) {
      this.$router.push({ path: '/F_freeDetails', query: { goodUrl: item.url, goodId: item.product_id } })
    }
  }
}
</script>

<style lang='less' scoped>
.dealRequest {
  .card-header {
    padding: 0 20px;
    margin-bottom: 0;
    background-color: #f0f3f5;
    border-bottom: 1px solid #c8ced3;
    span {
      margin-left: 10px;
      line-height: 45px;
    }
  }
  //底部商品栏
  .requestList {
    background-color: white;
    padding: 20px;
    // 商品栏
    .requestBox {
      width: 100%;
      box-sizing: border-box;
      padding: 10px 20px;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .goodItd {
        display: flex;
        justify-content: flex-start;
        width: 40%;
        // 商品图片
        .reqImg {
          img {
            max-height: 100px;
            max-width: 100px;
          }
        }
        // 商品名称和时间
        .reqGoods {
          width: calc(100% - 130px);
          margin-left: 30px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 100px;
          font-size: 14px;
          .reqName {
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              line-height: 2;
              &:nth-child(1) {
                display: inline-block;
                width: 21px;
                height: 14px;
                background: url("../../assets/img/flag.png") no-repeat center;
                background-size: cover;
              }
              &:nth-child(2) {
                width: calc(100% - 24px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-left: 10px;
                cursor: pointer;
                color: #20a8d8;
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .reqTime {
            line-height: 2;
          }
        }
        .reqSell {
          width: 95%;
          line-height: 2;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
      }
      // 商品卖方状态等
      .reqCuntent {
        display: flex;
        align-items: center;
        min-height: 100px;
        font-size: 14px;
        div {
          line-height: 2;
        }
      }
      // 删除或购买商品
      .reqBtn {
        /deep/ .el-button {
          padding: 5px 10px;
          /deep/ i {
            font-size: 16px;
          }
        }
        /deep/ .el-button--primary.is-plain {
          color: white;
        }
        .success {
          background-color: #4dbd74;
          border-color: #4dbd74;
        }
        .error {
          background-color: #f86c6b;
          border-color: #f86c6b;
        }
        .round {
          background-color: #20a8d8;
          border-color: #20a8d8;
        }
        .share {
          background-color: #2f353a;
          border-color: #2f353a;
        }
      }
      // 消息提醒
      .information {
        position: absolute;
        right: -10px;
        top: -10px;
        // display: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: white;
        background-color: tomato;
        text-align: center;
        line-height: 20px;
        font-weight: bolder;
      }
    }
  }
}
</style>
